// React
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserListFromId } from "../../../actions/userList";
import UserListEdit from "../../../containers/UserList/EditUserList";
import Spinner from "../../../components/Spinner/Spinner";
import Icon from "utils/icon";
import UserLogged from "../../../containers/userLogged";

const EditUserList = ({ pubId }) => {
  const dispatch = useDispatch();
  const get = () => dispatch(getUserListFromId(pubId));

  const { userListSelected } = useSelector((state) => state.userList);

  useEffect(() => {
    if (pubId) {
      get();
    }
  }, []);

  return (
    (Object.keys(userListSelected).length && (
      <UserLogged>        
        <UserListEdit returnUrl={"/ul"} userList={userListSelected} />
      </UserLogged>
    )) || (
      <Spinner>
        <Icon icon="LoadingHalf" />
      </Spinner>
    )
  );
};

export default EditUserList;
